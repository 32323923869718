<template>
    <v-container fluid>
        <div class="d-flex mb-6" style="gap: 20px">
            <v-spacer />
            <v-menu 
            v-model="menu"
            transition="scale-transition" 
            offset-y
            :close-on-content-click="true">
                <template v-slot:activator="{ on, attrs }">
                <v-text-field style="max-width:fit-content" :value="date ? date.toLocaleDateString('esp', { month : 'long', year : 'numeric' }).toUpperCase() : ''" append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker type="month" @click:month="menu = false" no-title  @change="moveDate($event)" scrollable>
                </v-date-picker>
            </v-menu>
            <v-spacer />
            <v-btn @click.stop="exportar" color="primary" class="me-5">Exportar a Excel<v-icon class="ms-2">mdi-download</v-icon></v-btn>
        </div>
        <v-card class="ma-5">
            <div :class="'v-data-table ' + ($vuetify.theme.dark ? 'theme--dark' : 'theme--light')">
                <div class="v-data-table__wrapper">
                    <v-simple-table id="tabla">
                        <thead style="position: sticky !important;top: 0 !important">
                            <tr>
                                <th rowspan="2" data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" style="border-bottom: 8px solid var(--v-secondary-base) !important;padding:5px !important;border-right: 4px solid var(--v-secondary-base) !important;position:sticky !important; left: 0 !important;z-index: 1000" class="text-center primary--text font-weight-black text-subtitle-1"><v-text-field style="width:300px" placeholder="EMPLEADO" v-model="searchEmpleado" hide-details class="v-superdense-input"></v-text-field></th>
                                <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" style="border-right: 4px solid var(--v-secondary-base) !important; padding:5px !important;border-left: 4px solid var(--v-secondary-base) !important;" colspan="4" class="text-center primary--text font-weight-black text-subtitle-1">TOTALES (€)</th>
                                <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" style="border-left: 4px solid var(--v-secondary-base) !important; padding:5px !important;border-right: 4px solid var(--v-secondary-base) !important;" colspan="3" class="text-center primary--text font-weight-black text-subtitle-1">TOTALES (HORAS y KMS)</th>
                                <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" v-for="d in days" style="cursor:pointer;padding:5px !important;border-left: 4px solid var(--v-secondary-base) !important;border-right: 4px solid var(--v-secondary-base) !important;" colspan="4" class="text-center primary--text font-weight-black text-subtitle-1" @click="$router.push({ path:`/horas/${date.getFullYear()}-${('0'+(date.getMonth()+1)).slice(-2)}-${('0'+d).slice(-2)}` })">{{ d }}</th>
                            </tr>
                            <tr>
                                <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" class="text-center" style="border-bottom: 8px solid var(--v-secondary-base) !important;border-left: 4px solid var(--v-secondary-base) !important;border-right: 4px solid var(--v-secondary-base) !important;">TOTAL NETO</th>
                                <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" class="text-center" style="border-bottom: 8px solid var(--v-secondary-base) !important;border-left: 4px solid var(--v-secondary-base) !important;">EVENTOS</th>
                                <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" class="text-center" style="border-bottom: 8px solid var(--v-secondary-base) !important;">MONTAJES</th>
                                <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" style="border-bottom: 8px solid var(--v-secondary-base) !important;border-right: 4px solid var(--v-secondary-base) !important;" class="text-center">KMS</th>
                                <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" style="border-bottom: 8px solid var(--v-secondary-base) !important;border-left: 4px solid var(--v-secondary-base) !important;" class="text-center">HS EVENTOS</th>
                                <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" class="text-center" style="border-bottom: 8px solid var(--v-secondary-base) !important;">HS MONTAJES</th>
                                <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" class="text-center" style="border-bottom: 8px solid var(--v-secondary-base) !important;border-right: 4px solid var(--v-secondary-base) !important;">KMS</th>
                                <template v-for="d in days">
                                    <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" class="text-center" style="border-left: 4px solid var(--v-secondary-base) !important;border-bottom: 8px solid var(--v-secondary-base) !important;border-bottom: 8px solid var(--v-secondary-base) !important;">HS EV</th>
                                    <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" class="text-center" style="border-bottom: 8px solid var(--v-secondary-base) !important;">KMS EV</th>
                                    <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" class="text-center" style="border-bottom: 8px solid var(--v-secondary-base) !important;">HS MONT</th>
                                    <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" class="text-center" style="border-bottom: 8px solid var(--v-secondary-base) !important;border-right: 4px solid var(--v-secondary-base) !important;">KMS MONT</th>
                                </template>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="empleado,i in modelo.filter(m => String((empleados.find( emp => emp[0] == m.idUnidadEquipo )?.nombre || '')+' '+(empleados.find( emp => emp[0] == m.idUnidadEquipo )?.apellidos || '')).toUpperCase().includes(searchEmpleado.toUpperCase()))">
                                <td @click.stop="$router.push({path:`/horas_mensuales/${empleado[0]}`})" style="position:sticky !important; left: 0 !important;z-index: 1000;cursor: pointer;white-space: nowrap;border-right: 4px solid var(--v-secondary-base) !important;">{{ empleados.filter( emp => empleado[0] == emp.idUnidadEquipo )[0].nombre }} {{ empleados.filter( emp => empleado[0] == emp.idUnidadEquipo )[0].inicialesApellidos || empleados.filter( emp => empleado[0] == emp.idUnidadEquipo )[0].apellidos }}</td>
                                <!-- totales -->
                                <template v-for="datos,i in empleado.slice(1,8)">
                                    <td class="text-center" :style="'z-index: 1;white-space: nowrap; ' + (i == 0 ? 'color: var(--v-info-base);font-weight: bold;font-size: larger;border-left: 4px solid var(--v-secondary-base) !important;border-right: 4px solid var(--v-secondary-base) !important;' : ((i == 3 || i == 6) ? 'border-right: 4px solid var(--v-secondary-base) !important;' : (i == 4 || i == 1 ? 'border-left: 4px solid var(--v-secondary-base) !important;' : '')))">{{ datos + ((i == 0) ? varios.filter(v => v.idUnidadEquipo == empleado[0] && new Date(v.date).getFullYear() == date.getFullYear() && new Date(v.date).getMonth() == date.getMonth()).map(va => va.varios).reduce((acc,sum) => acc += sum,0) : (i == 1 ? varios.filter(v => v.idUnidadEquipo == empleado[0] && new Date(v.date).getFullYear() == date.getFullYear() && new Date(v.date).getMonth() == date.getMonth() && (v.pHoraAux == "Ev" || v.pHoraAux == "Top")).map(va => va.varios).reduce((acc,sum) => acc += sum,0) : (i == 2 ? varios.filter(v => v.idUnidadEquipo == empleado[0] && new Date(v.date).getFullYear() == date.getFullYear() && new Date(v.date).getMonth() == date.getMonth() && v.pHoraAux == "M").map(va => va.varios).reduce((acc,sum) => acc += sum,0) : 0))) }} {{ i < 4 ? '€' : '' }}</td>
                                </template>
                                <!-- dias -->
                                <template v-for="d in days">
                                    <td class="text-center" :style="'z-index: 1;border-left: 4px solid var(--v-secondary-base) !important;' + (empleado.slice(7)[d][3] + empleado.slice(7)[d+(days*2)][3] != 0 ? 'color: var(--v-info-base);font-weight: bold;font-size: large;' : '')">{{ empleado.slice(7)[d][3] + empleado.slice(7)[d+(days*2)][3] }}</td>
                                    <td :style="(empleado.slice(7)[d][1] + empleado.slice(7)[d+(days*2)][1] != 0 ? 'color: var(--v-info-base);font-weight: bold;font-size: large;' : '') + 'z-index: 1'" class="text-center">{{ empleado.slice(7)[d][1] + empleado.slice(7)[d+(days*2)][1] }}</td>
                                    <td :style="(empleado.slice(7)[d+days][3] != 0 ? 'color: var(--v-info-base);font-weight: bold;font-size: large;' : '') + 'z-index: 1'" class="text-center">{{ empleado.slice(7)[d+days][3] }}</td>
                                    <td :style="(empleado.slice(7)[d+days][1] != 0 ? 'color: var(--v-info-base);font-weight: bold;font-size: large;' : '') + 'z-index: 1;border-right: 4px solid var(--v-secondary-base) !important;'" class="text-center">{{ empleado.slice(7)[d+days][1] }}</td>
                                </template>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </div>
        </v-card>
    </v-container>
</template>

<script>

import TableToExcel from "@linways/table-to-excel";
export default {
    data(){
        return {
            date: new Date(new Date().getUTCFullYear(),new Date().getUTCMonth(),1,12,0,0),
            days: 0,
            modelo: [],
            varios: [],
            menu: false,
            searchEmpleado: '',
            empleados:[],
            pag: 0,
            paginas: 0,
            loading: true,
            saving: false,
        }
    },
    methods:{
        moveDate(m) {
            this.loading = true
            const date = new Date(this.date);
            date.setYear(parseInt(m.split("-")[0]));
            date.setMonth(parseInt(m.split("-")[1]) - 1);
            this.date = date
            this.days = new Date(this.date.getFullYear(), this.date.getMonth()+1,0,12,0,0).getDate()
            this.empleados = []
            this.modelo = []
            this.pag = 0
            this.getEmpleados()
        },
        async getEmpleados(){
            const emps = await axios({
                url: `/horas_mes/empleados/${this.date.toISOString().split('T')[0]}`
            });
            this.empleados = emps.data;
            this.paginas = Math.floor(this.empleados.length / 5)
            const { data } = await axios({
                url: `/horas_mes/${this.date.toISOString().split('T')[0]}`,
                params: {
                    date: this.date,
                },
            });
            this.modelo = data;
            
            const varios = await axios({
                url: `/horas_mes/varios/${new Date(this.date.getUTCFullYear(), this.date.getUTCMonth(),1,12,0,0).toISOString().split('T')[0]}`
            });
            this.varios = varios.data
            this.loading = false;
        },
        exportar(){
            var tabla = document.getElementById("tabla").getElementsByTagName("table")[0]

            var libroBodas = TableToExcel.tableToBook(tabla, {
                sheet: {
                    name: "HORAS MENSUALES"
                }
            })
            TableToExcel.save(libroBodas, "HORAS MENSUALES - " + this.date.toLocaleDateString("esp", {month: "long", year:"numeric"}) + ".xlsx")
        }
    },
    mounted(){
        this.days = new Date(this.date.getFullYear(), this.date.getMonth()+1,0,12,0,0).getDate()
        this.getEmpleados()
    }
};
</script>
<style scoped>
table tr th {
    border: 1px solid var(--v-secondary-base) !important;
    white-space: nowrap;
}
.centered-input >>> input {
    text-align: center;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
table tr:nth-child(even){
    backdrop-filter: brightness(1.5);
}

.v-data-table.theme--light table > tbody > tr:not(.v-data-table__selected) > td:nth-child(1):not(.remove-filter), .v-data-table.theme--light table > thead > tr:not(.v-data-table__selected):not(:hover) > th:nth-child(1):not(.remove-filter) {
    background: #ffffff;
}
.v-data-table.theme--dark table > tbody > tr:not(.v-data-table__selected) > td:nth-child(1):not(.remove-filter), .v-data-table.theme--dark table > thead > tr:not(.v-data-table__selected):not(:hover) > th:nth-child(1):not(.remove-filter) {
    background: #1e1e1e;
}
#tabla thead{
    position: -webkit-sticky !important; /* for Safari */
    position: sticky !important;
    top: 0 !important;
}
</style>